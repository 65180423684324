import React, {useCallback, useEffect, useState} from 'react';
import {
	MantineProvider,
	Container,
	Space,
	Loader,
	List,
	ThemeIcon,
	TextInput,
	Text,
	Pagination,
	Select,
	Image,
	JsonInput,
	SimpleGrid, Divider
} from '@mantine/core';
import { Prism } from '@mantine/prism';
import {CircleCheck, Database, Search} from 'tabler-icons-react';
import Scout from "scout-js-client";
import {TDynamicObject} from "scout-js-client/dist/src/@types/TInternal";
type TResponse = {
	index: string,
	hits: number,
	nb_hits: number,
	data: Array<any>,
	execution: string,
	fetch: string
};
type TOffsetLimit = {
	limit: number,
	offset: number
};
const _response: TResponse = {
	data: [],
	execution: "",
	hits: 0,
	index: "",
	nb_hits: 0,
	fetch: ""
};
const _offsetLimit: TOffsetLimit = {
	offset: 0,
	limit: 20
};
const scout = new Scout("https://scout.easysales.asia");
function App() {
	const [ indexes, setIndexes ] = useState<string[]>([]);
	const [ index, setIndex ] = useState("");
	const [ query, setQuery ] = useState<string>("");
	const [ response, setResponse ] = useState<TResponse>(_response);
	const [ loading, setLoading ] = useState<boolean>(false);
	const [ offsetLimit, setOffsetLimit ] = useState<TOffsetLimit>(_offsetLimit);
	const [ filter, setFilter ] = useState<TDynamicObject>({});
	const [ page, setPage ] = useState(0);
	useEffect(()=>{
		(async ()=>{
			const api_indexes = await scout.indexes();
			setIndexes(api_indexes.indexes);
		})();
	},[]);
	const queryToSearchEngine = (text: string, _index = "", _filter = {}, reset_offset = false, _page = -1):void =>{
		if (!text && !_index){
			setResponse(_response);
			setOffsetLimit(_offsetLimit);
			return;
		}
		setLoading(true);
		const json = {
			"index": _index ? _index : index,
			"query": text,
			"filter": {
				..._filter
			},
			"offset": reset_offset ? 0 : (_page !== -1 ? _page : page) * offsetLimit.limit,
			"limit": offsetLimit.limit
		};
		console.log("queryToSearchEngine::",json);
		scout.search(json).then((data)=>{
			setResponse(data);
			setLoading(false);
			if (data.nb_hits === 0 || query !== text){
				setOffsetLimit(_offsetLimit);
			} else {
				setOffsetLimit({
					...offsetLimit,
					offset: offsetLimit.offset + data.nb_hits
				});
			}
		}).catch(er=>{
			console.log("Search engine :: ",er.message);
		});
		setQuery(text);
	}
	const onIndex = (_index: string | null) =>{
		_index && setOffsetLimit({
			offset: 0,
			limit: 80,
		});
		_index && setIndex(_index);
		_index && queryToSearchEngine("",_index);
	}
	const onJson = (text: string) =>{
		if (!text){
			queryToSearchEngine(query);
			return;
		}
		try {
			const json = JSON.parse(text);
			queryToSearchEngine(query,index,json,true);
			setFilter(json);
		} catch (e){

		}
	}
	const onQuery = useCallback(queryToSearchEngine,[index, offsetLimit, page, query]);
	const onChangePage = (_page: number) =>{
		setPage(_page);
		onQuery(query,index,filter,false,_page-1);
	};
	const onChangeQuery = (text: string) =>{
		onQuery(text,index,filter,true,0);
		setPage(0);
	};
	return (
		<MantineProvider
			theme={{
				spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 },
			}}>
			<Container>
				<Space h={"xl"}/>
				<Image
					width={"35%"}
					height={"20%"}
					fit="contain"
					src={require("./scout.png")}
				/>
				<SimpleGrid cols={2}>
					<Select
						value={index}
						onChange={onIndex}
						label="Index / Database"
						placeholder="Pick an index"
						data={indexes}
						icon={<Database size={14}/>}
					/>
					<TextInput
						label={"Your query goes here"}
						disabled={!index}
						onChange={(event)=>onChangeQuery(event.target.value)}
						placeholder="Scones with Jam"
						rightSection={loading ? <Loader size={"sm"}/> : null}
						icon={<Search size={14} />} />
				</SimpleGrid>

				<Space h={"xs"}/>

				<JsonInput
					label="Your filters"
					placeholder={`
{
  "product_status": {
    "eq": "1"
  }
}
					`}
					validationError="Invalid json"
					formatOnBlur
					autosize
					minRows={4}
					onChange={onJson}
				/>

				<Space h={"md"}/>
				{
					response.data?.length > 0 && (
						<>
							<Text weight={700}>
								{response.hits.toLocaleString()} hits in {response?.execution}, showing {response?.nb_hits} results (fetch {response?.fetch})
							</Text>
							<Space h={"md"}/>
						</>
					)
				}
				{
					(response.data?.length > 0) && (
						<Pagination
							page={page}
							onChange={onChangePage}
							color="teal"
							total={Math.floor(response.hits / offsetLimit.limit)}/>
					)
				}
				<Space h={"md"}/>
				<List
					spacing="xs"
					size="sm"
					center
					icon={
						<ThemeIcon color="teal" size={24} radius="xl">
							<CircleCheck size={16} />
						</ThemeIcon>
					}>
					{
						response.data?.map((item,index)=>
							<div key={String(index).concat("--list-item")}>
								<Prism
									scrollAreaComponent={"div"}
									withLineNumbers={true}
									language="json">
									{JSON.stringify(item, null, 2)}
								</Prism>
								<Divider/>
							</div>
						)
					}
				</List>
				<Space h={"xl"}/>
			</Container>
		</MantineProvider>
	);
}

export default App;
